body {
  font-family: "Helvetica";
  font-weight: 300;
  padding: 0;
  margin: 0;
  color: #212121;
  background: #ffffff;
  font-size: 16px;
  line-height: 22px;
  @media screen and (max-width: 1180px) {
    font-size: 14px;
    line-height: 20px;
  }
  a {
    text-decoration: none !important;
  }
  * {
    -webkit-tap-highlight-color: transparent;
  }
  overflow: hidden;
}
.sign {
  position: relative;
  display: block;
  overflow: hidden;
  min-height: 100vh;
  @media screen and (max-width: 1180px) {
    min-height: 677px;
    height: 100%;
  }
  .top-img {
    position: absolute;
    display: block;
    width: calc(100vw - 600px);
    height: 100%;
    background: url(../img/top.jpg) no-repeat 50% 50%;
    background-size: cover;
    left: 0;
    @media screen and (max-width: 1180px) {
      width: 100%;
      height: calc(100% - 500px);
      background-position: 50% 30%;
    }
  }
  #right-block {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    right: 0;
    top: 0;
    width: 600px;
    padding: 30px 40px;
    box-sizing: border-box;
    height: 100%;
    @media screen and (max-width: 1180px) {
      width: 100%;
      height: 500px;
      top: auto;
      bottom: 0;
      padding: 20px;
      padding-top: 30px;
    }
    #auth-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      @media screen and (max-width: 1180px) {
        display: none;
      }
    }
    #auth-footer {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: auto;
      gap: 20px;
      a {
        position: relative;
        display: block;
        text-decoration: none;
        color: #909090;
        box-sizing: border-box;
        &:hover {
          color: #208ad7;
        }
      }
    }
    #login-form {
      position: relative;
      display: block;
      font-size: 16px;
      width: 340px;
      text-align: center;
      @media screen and (max-width: 1180px) {
        width: 100%;
        font-size: 14px;
        line-height: 20px;
      }
      #auth-logo {
        @media screen and (max-width: 1180px) {
          width: 160px;
        }
      }
      b {
        position: relative;
        display: block;
        font-size: 30px;
        font-weight: 900;
        line-height: 36px;
        margin-bottom: 5px;
        margin-top: 30px;
        @media screen and (max-width: 1180px) {
          font-size: 26px;
          margin-top: 20px;
        }
      }
      #bolddesc {
        color: #208bd8;
        font-weight: 500;
      }

      .login-input {
        position: relative;
        display: inline-block;
        width: 340px;
        height: 46px;
        margin-bottom: 5px;
        overflow: hidden;
        @media screen and (max-width: 1180px) {
          width: 100%;
        }
        input {
          position: relative;
          display: block;
          font-family: "Helvetica";
          width: 100%;
          height: 100%;
          margin: 0;
          font-size: 16px;
          padding: 0 10px;
          box-sizing: border-box;
          outline: none;
          font-weight: 300;
          border: #ccc solid 1px;
          border-radius: 8px;
          &:focus {
            border-color: #208ad7;
          }
        }
      }
      .auth-error {
        position: relative;
        display: none;
        color: red;
        text-align: left;
        margin-bottom: 10px;
        font-size: 14px;
      }
      #auth-buttons {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        #login-btn {
          position: relative;
          display: block;
          font-size: 16px;
          background: #208ad7;
          color: #fff;
          text-align: center;
          border-radius: 8px;
          height: 46px;
          line-height: 46px;
          width: 120px;
          cursor: pointer;
          letter-spacing: 1px;
          &:hover {
            background: #359ae3;
          }
        }
        #reg-btn {
          position: relative;
          display: block;
          font-size: 16px;
          background: #208ad7;
          color: #fff;
          text-align: center;
          border-radius: 8px;
          height: 46px;
          line-height: 46px;
          width: 220px;
          cursor: pointer;
          letter-spacing: 1px;
          &:hover {
            background: #359ae3;
          }
        }
        #recover-btn {
          position: relative;
          display: block;
          font-size: 16px;
          background: #208ad7;
          color: #fff;
          text-align: center;
          border-radius: 8px;
          height: 46px;
          width: 190px;
          cursor: pointer;
          letter-spacing: 1px;
          line-height: 42px;
          border: 0;
          &:hover {
            background: #359ae3;
          }
        }
        a {
          text-decoration: none;
          color: #909090;
          box-sizing: border-box;
          &:hover {
            color: #208ad7;
            border-color: #208ad7;
          }
        }
      }
      form {
        #auth-buttons {
          margin-top: 30px;
        }
      }
      #reg-new-user {
        position: relative;
        display: inline-block;
        margin-top: 30px;
        a {
          text-decoration: none;
          color: #208ad7;
          box-sizing: border-box;
          &:hover {
            color: #359ae3;
            border-bottom: #208ad7 solid 1px;
          }
        }
      }
    }
  }
}

section {
  &#news-pass {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
  }

  &.personal {
    min-height: 100vh;
    * {
      user-select: none;
    }
    #personal-calendar {
      position: absolute;
      display: block;
      right: 0;
      height: 100vh;
      width: 50vw;
      background: #ffffff;
      overflow: hidden;
      &.with-scroll {
        overflow-y: scroll;
      }
      @media screen and (max-width: 1180px) {
        display: none;
        height: 100%;
        width: 100%;
        &.active {
          display: block;
        }
      }
      #personal-right-header {
        position: relative;
        display: flex;
        justify-content: space-between;
        padding: 30px 40px;
        .try-button {
          cursor: pointer;
          padding: 0 16px;
          border-radius: 5px;
          text-align: center;
          font-size: 16px;
          height: 40px;
          color: #fff;
          background: #004170;
          border: none;
        }
        @media screen and (max-width: 1180px) {
          display: none;
        }
        a {
          cursor: pointer;
          font-size: 16px;
          line-height: 40px;
          color: #004170;
          text-decoration: none;
        }
      }

      #close-mobile-calendar {
        position: absolute;
        display: none;
        right: 20px;
        bottom: 24px;
        width: 110px;
        border-radius: 5px;
        text-align: center;
        line-height: 40px;
        height: 40px;
        color: #fff;
        background: #212121;
        @media screen and (max-width: 1180px) {
          display: block;
        }
      }
      #calendar-summary {
        position: absolute;
        display: flex;
        bottom: 40px;
        width: 100%;
        justify-content: center;
        gap: 20px;
        font-size: 18px;
        line-height: 20px;
        @media screen and (max-width: 1180px) {
          bottom: 20px;
          font-size: 16px;
          line-height: 20px;
          margin-left: 10px;
          gap: 5px;
          flex-direction: column;
        }
        .calendar-summary-plus {
          b {
            color: #069f08;
          }
        }
        .calendar-summary-minus {
          b {
            color: #ef5252;
          }
        }
      }
      #calendar-title {
        position: relative;
        display: block;
        box-sizing: border-box;
        padding: 12px 40px 8px;
        background: #0065ad0d;
        color: #212121;
        font-size: 30px;
        line-height: 34px;
        text-align: center;
        .calendar-year {
          font-size: 15px;
          line-height: 23px;
        }
        #prev-month {
          position: absolute;
          display: block;
          left: 20px;
          top: 22px;
          width: 30px;
          text-align: left;
          cursor: pointer;
          img {
            width: 20px;
            transform: rotate(180deg);
          }
        }
        #next-month {
          position: absolute;
          display: block;
          right: 20px;
          top: 22px;
          width: 30px;
          text-align: right;
          cursor: pointer;
          img {
            width: 20px;
          }
        }
      }
      .calendar-week {
        position: relative;
        display: flex;
        justify-content: space-around;
        width: calc(100% - 80px);
        margin: 0 auto;
        padding-top: 8px;
        border-top: #fff solid 1px;
        font-weight: 300;
        font-size: 20px;
        line-height: 40px;
        text-align: center;
        margin-bottom: 12px;
        @media screen and (max-width: 1180px) {
          width: calc(100% - 20px);
        }
      }
      .calendar-days {
        position: relative;
        display: flex;
        width: calc(100% - 80px);
        margin: 0 auto;
        flex-wrap: wrap;
        margin-bottom: 10px;
        justify-content: space-between;
        @media screen and (max-width: 1180px) {
          width: calc(100% - 20px);
        }
        .calendar-days-day {
          position: relative;
          display: none;
          width: 100%;
          flex-wrap: wrap;
          margin-bottom: 10px;
          justify-content: space-between;
          &.active {
            display: flex;
          }
        }
        .calendar-day {
          position: relative;
          display: block;
          width: 14.1%;
          height: calc((100vh - 320px) / 6);
          border: #fff solid 2px;
          border-right: 0;
          box-sizing: border-box;
          line-height: 50px;
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          text-align: center;
          margin-bottom: -1px;
          background: #0065ad0d;
          border-radius: 10px;
          @media screen and (max-width: 1180px) {
            border-radius: 5px;
            height: calc((100vh - 280px) / 6);
          }
          &.current {
            background: #00f0024a;
            &.fill {
              background: #00f0024a;
            }
            &:hover {
              background: rgba(0, 240, 2, 0.38);
            }
          }
          &:hover {
            background: rgba(0, 101, 173, 0.1);
          }
          &.fill {
            background: rgba(0, 101, 173, 0.1);
          }
          span {
            position: absolute;
            right: 10px;
            top: 10px;
            font-size: 20px;
            line-height: 20px;
            font-weight: 400;
            color: #00406e;
            @media screen and (max-width: 1180px) {
              right: 8px;
              top: 8px;
            }
          }
          .calendar-sums {
            position: absolute;
            display: block;
            bottom: 5px;
            left: 10px;
            font-weight: 500;
            font-size: 0.9vw;
            line-height: 1vw;
            text-align: left;
            @media screen and (min-width: 1440px) {
              font-size: 14px;
              line-height: 16px;
            }
            @media screen and (max-width: 1180px) {
              font-size: 8px;
              line-height: 11px;
              left: 6px;
            }
            .minus-sum {
              color: red;
            }
            .plus-sum {
              color: green;
            }
          }
          //@media screen and (max-width:760px){
          //  font-size: 12px;
          //  height: 36px;
          //  line-height: 36px;
          //}
          &.empty {
            background: none;
          }
          &:nth-child(7n) {
            border-right: #fff solid 2px;
          }
        }
      }

      #why-pro-is-good {
        h1 {
          position: relative;
          display: block;
          margin: 0;
          padding: 0;
          line-height: 76px;
          background: #0065ad0d;
          text-align: center;
        }
        .pro-fixed-wrapper {
          position: fixed;
          display: none;
          bottom: 20px;
          justify-content: center;
          align-items: center;
          width: 100%;
          gap: 16px;
          @media screen and (max-width: 1180px) {
            display: flex;
          }
          .try-button {
            border-radius: 5px;
            text-align: center;
            height: 40px;
            color: #fff;
            cursor: pointer;
            padding: 0 16px;
            background: #004170;
            vertical-align: middle;
            border: none;
          }
          #mobile-close-pro {
            border: none;
            vertical-align: middle;
            padding: 0 16px;
            cursor: pointer;
            border-radius: 5px;
            text-align: center;
            height: 40px;
            color: #fff;
            background: #069f07;
          }
        }
        #pro-advantages-with-banner {
          position: relative;
          display: flex;
          justify-content: space-between;
          padding: 30px;
          #banner {
            width: 240px;
            height: 400px;
            background: #ccc;
            @media screen and (max-width: 1280px) {
              display: none;
            }
          }
          #pro-advantages {
            position: relative;
            display: flex;
            flex-direction: column;
            width: calc(100% - 240px - 90px);
            gap: 50px;
            @media screen and (max-width: 1280px) {
              width: auto;
              padding-bottom: 80px;
            }
            .pro-advantage {
              position: relative;
              display: flex;
              align-items: flex-start;
              justify-content: space-between;
              gap: 30px;
              &:nth-child(2n) {
                flex-direction: row-reverse;
              }
              @media screen and (max-width: 2050px) {
                flex-direction: column;
                &:nth-child(2n) {
                  flex-direction: column;
                }
              }
              img {
                border-radius: 20px;
                width: 300px;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                @media screen and (max-width: 480px) {
                  width: 100%;
                  border-radius: 10px;
                }
              }
              .pro-advantage-info {
                position: relative;
                display: block;
                font-size: 18px;
                line-height: 24px;
                b {
                  position: relative;
                  display: block;
                  font-size: 24px;
                  line-height: 30px;
                  margin-bottom: 20px;
                }
              }
            }
          }
        }
      }
    }

    #personal-left {
      position: absolute;
      left: 0;
      height: 100vh;
      width: 50vw;
      //background: #004170;
      background: linear-gradient(40deg, #004170, #01253f);
      @media screen and (max-width: 1180px) {
        width: 100%;
        height: 100%;
      }

      #personal-left-header {
        position: relative;
        display: flex;
        justify-content: space-between;
        padding: 30px 40px;
        color: rgba(255, 255, 255, 0.4);
        @media screen and (max-width: 1180px) {
          padding: 20px;
          font-size: 0;
        }
        img {
          @media screen and (max-width: 1180px) {
            width: 100px;
          }
        }
        #pro-btn {
          position: relative;
          display: inline-block;
          line-height: 36px;
          padding: 0 20px;
          border-radius: 6px;
          border: #fff solid 2px;
          font-size: 16px;
          font-weight: 400;
          opacity: 0.4;
          color: #fff;
          cursor: pointer;
          margin-left: 5px;
          @media screen and (max-width: 1180px) {
            margin-right: 40px;
            padding: 0 10px;
            line-height: 24px;
            font-size: 12px;
            border: #fff solid 1px;
          }
          &:hover {
            opacity: 1;
          }
          &.pro {
            pointer-events: none;
            color: yellow;
            border-color: yellow;
            opacity: 1;
          }
        }
        #mobile-exit {
          position: absolute;
          display: none;
          right: 20px;
          top: 20px;
          opacity: 0.5;
          @media screen and (max-width: 1180px) {
            display: block;
          }
          img {
            height: 26px;
            width: 26px;
          }
        }
      }
      #statistics {
        position: relative;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 12px 40px 8px;
        background: #ffffff0d;
        @media screen and (max-width: 1180px) {
          padding: 12px 20px 8px;
        }
        .stat-block {
          width: 33.3333%;
          color: #fff;
          font-size: 16px;
          line-height: 22px;
          @media screen and (max-width: 1180px) {
            font-size: 13px;
            line-height: 18px;
            width: auto;
          }
          b {
            position: relative;
            display: block;
            font-size: 26px;
            line-height: 34px;
            color: #49e549;
            @media screen and (max-width: 1180px) {
              font-size: 18px;
              line-height: 22px;
            }
          }
          span {
            opacity: 1;
          }
          &.minus {
            b {
              color: #ef5252;
            }
          }
        }
      }

      #bookmarks {
        position: relative;
        display: flex;
        justify-content: flex-start;
        padding: 30px 40px;
        gap: 10px;
        @media screen and (max-width: 1180px) {
          justify-content: space-between;
          padding: 20px;
          gap: 0;
          padding-bottom: 75px;
        }

        .bookmark {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 20px;
          border-radius: 10px;
          background: rgba(255, 255, 255, 0.1);
          color: #fff;
          font-size: 16px;
          cursor: pointer;
          line-height: 40px;
          @media screen and (max-width: 1180px) {
            border-radius: 5px;
            padding: 0;
            line-height: 30px;
            width: calc(33.333% - 5px);
            font-size: 14px;
          }
          &:hover {
            background: rgba(255, 255, 255, 0.2);
          }
          &.active {
            background: #fff;
            color: #212121;
          }
        }

        #add-button {
          background: #069f07 url(../img/plus.svg) no-repeat 14px 50%;
          border-radius: 60px;
          cursor: pointer;
          display: block;
          height: 40px;
          line-height: 40px;
          position: absolute;
          color: #fff;
          padding: 0 20px 0 40px;
          right: 40px;
          top: 30px;
          &:hover {
            background-color: #0ab50b;
          }

          @media screen and (max-width: 1180px) {
            right: auto;
            top: 66px;
          }
        }
        #calendar-btn {
          position: absolute;
          display: none;
          right: 20px;
          top: 66px;
          background: #fff url(../img/calendar.svg) no-repeat 50% 50%;
          background-size: 20px 20px;
          width: 40px;
          height: 40px;
          border-radius: 60px;
          @media screen and (max-width: 1180px) {
            display: block;
          }
        }
      }

      #debts-list-empty {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 20px;
        width: 100%;
        box-sizing: border-box;
        height: calc(100% - 276px);
        padding: 0px 40px;
        overflow-y: auto;
        #add-empty-button {
          background: #1b4462 url(../img/plus.svg) no-repeat 14px 50%;
          border-radius: 60px;
          cursor: pointer;
          display: block;
          font-size: 16px;
          height: 40px;
          line-height: 40px;
          position: relative;
          color: #fff;
          padding: 0 20px 0 40px;
        }
        @media screen and (max-width: 1180px) {
          padding: 0px 20px;
          height: calc(100% - 252px);
        }
      }
      #debts-list {
        position: relative;
        display: block;
        width: 100%;
        box-sizing: border-box;
        height: calc(100% - 276px);
        padding: 0px 40px;
        overflow-y: scroll;
        @media screen and (max-width: 1180px) {
          padding: 0px 20px;
          height: calc(100% - 252px);
        }
        &::-webkit-scrollbar {
          width: 5px;
          height: 8px;
        }
        &::-webkit-scrollbar-thumb {
          background: rgba(255, 255, 255, 0.2);
        }
        .debts-list-item {
          position: relative;
          padding: 14px 20px;
          border-radius: 10px;
          box-sizing: border-box;
          background: #ffffff0d;
          margin-bottom: 10px;
          @media screen and (max-width: 1180px) {
            padding: 10px 14px;
            font-size: 12px;
            line-height: 16px;
          }
          button.delete-btn {
            position: absolute;
            cursor: pointer;
            width: 30px;
            height: 30px;
            top: 20px;
            right: 20px;
            border: none;
            border-radius: 30px;
            background-position: center;
            background-size: 24px 24px;
            background-color: transparent;
            background-repeat: no-repeat;
            background-image: url(../img/delete.svg);
            @media screen and (max-width: 1180px) {
              right: 14px;
              top: 14px;
              width: 24px;
              height: 24px;
            }
          }
          &.repeat {
            &.delete {
              &:after {
                right: 66px;
                @media screen and (max-width: 1180px) {
                  right: 48px;
                }
              }
            }
            &:after {
              position: absolute;
              display: block;
              width: 30px;
              height: 30px;
              pointer-events: none;
              opacity: 0.2;
              content: "";
              right: 20px;
              top: 20px;
              background: url(../img/repeat.png) no-repeat;
              background-size: 100% 100%;
              @media screen and (max-width: 1180px) {
                right: 14px;
                top: 14px;
                width: 24px;
                height: 24px;
              }
            }
          }

          &:last-child {
            margin-bottom: 30px;
          }
          span {
            position: relative;
            display: block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            color: #fff;
            font-size: 18px;
            line-height: 24px;
            font-weight: bold;
            @media screen and (max-width: 1180px) {
              font-size: 16px;
              line-height: 18px;
              margin-right: 40px;
            }
          }
          .debt-sum {
            position: relative;
            display: block;
            margin-top: 5px;
            font-size: 36px;
            line-height: 36px;
            font-weight: bold;
            color: #49e549;
            @media screen and (max-width: 1180px) {
              font-size: 24px;
              line-height: 24px;
              margin-bottom: 3px;
            }
          }
          .complete-debt {
            span {
              opacity: 0.5;
            }
            .debt-sum {
              color: #fff;
              opacity: 0.5;
            }
          }
          &.minus {
            .debt-sum {
              color: #ef5252;
            }
          }
          .debts-list-item-date {
            color: rgba(255, 255, 255, 0.4);
            b {
              //color: #fff;
            }
          }
          .debts-list-comment {
            position: relative;
            display: block;
            padding-top: 10px;
            color: #fff;
            font-size: 14px;
            line-height: 19px;
          }
          .debts-list-more {
            display: none;
            &.opened {
              display: block;
            }
          }
          .debts-list-show-more {
            position: absolute;
            display: block;
            right: 12px;
            top: 62px;
            width: 48px;
            height: 48px;
            border-radius: 48px;
            background: url(../img/drop_down_big.svg) no-repeat 50% 50%;
            cursor: pointer;
            &.opened {
              transform: rotate(180deg);
            }
            @media screen and (max-width: 1180px) {
              height: 28px;
              right: 12px;
              top: 50px;
              width: 28px;
            }
          }
          .debts-list-show-more-bg {
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
          }
          .credit-graphic {
            position: absolute;
            display: block;
            right: 12px;
            top: 12px;
            width: 48px;
            height: 48px;
            border-radius: 48px;
            background: #00416f url(../img/calendar.svg) no-repeat 50% 50%;
            cursor: pointer;
            &:hover {
              background-color: #013153;
            }
          }
          .debts-list-more-buttons {
            position: relative;
            display: flex;
            justify-content: space-between;
            margin-top: 14px;
            .debts-list-more-edit {
              position: relative;
              display: block;
              width: 48px;
              height: 48px;
              border-radius: 48px;
              background: #00416f url(../img/edit.svg) no-repeat 50% 50%;
              cursor: pointer;
              &:hover {
                background-color: #013153;
              }
              @media screen and (max-width: 1180px) {
                background-color: #316d98;
                &:hover {
                  background-color: #316d98;
                }
              }
            }
            .debts-list-more-right {
              position: relative;
              display: flex;
              justify-content: flex-end;
              gap: 10px;
              .debts-list-more-plus {
                position: relative;
                display: block;
                width: 48px;
                height: 48px;
                border-radius: 48px;
                background: #00416f url(../img/plus.svg) no-repeat 50% 50%;
                cursor: pointer;
                &:hover {
                  background-color: #013153;
                }
                @media screen and (max-width: 1180px) {
                  background-color: #316d98;
                  &:hover {
                    background-color: #316d98;
                  }
                }
              }
              .debts-list-more-minus {
                position: relative;
                display: block;
                width: 48px;
                height: 48px;
                border-radius: 48px;
                background: #00416f url(../img/minus.svg) no-repeat 50% 50%;
                cursor: pointer;
                &:hover {
                  background-color: #013153;
                }
                @media screen and (max-width: 1180px) {
                  background-color: #316d98;
                  &:hover {
                    background-color: #316d98;
                  }
                }
              }
              .debts-list-more-all {
                position: relative;
                display: block;
                width: auto;
                padding: 0 20px 0 40px;
                height: 48px;
                line-height: 48px;
                border-radius: 48px;
                background: #00416f url(../img/check-small.svg) no-repeat 14px 50%;
                color: #49e54a;
                cursor: pointer;
                &:hover {
                  background-color: #013153;
                }
                @media screen and (max-width: 1180px) {
                  background-color: #316d98;
                  &:hover {
                    background-color: #316d98;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

#modal-form {
  position: fixed;
  display: none;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  &.active {
    display: block;
  }
  .modal-block-flex {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;

    .modal-block {
      position: relative;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      max-width: 600px;
      border-radius: 20px;
      padding: 30px 40px;
      box-sizing: border-box;
      background: #004170;
      color: #fff;
      margin: 40px 0;
      @media screen and (max-width: 1180px) {
        border-radius: 0;
        padding: 20px;
      }
      .modal-block-title {
        position: relative;
        display: block;
        font-weight: 600;
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 30px;
        @media screen and (max-width: 1180px) {
          font-size: 20px;
          line-height: 30px;
          margin-bottom: 20px;
        }
      }
      .modal-input {
        position: relative;
        display: block;
        width: 100%;
        margin-bottom: 28px;
        &.half-block {
          width: calc(50% - 10px);
        }
        @media screen and (max-width: 1180px) {
          margin-bottom: 18px;
        }
        span {
          position: relative;
          display: block;
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 10px;
          opacity: 0.5;
          @media screen and (max-width: 1180px) {
            font-size: 12px;
            line-height: 16px;
          }
        }
        &.validate {
          span {
            opacity: 1;
            font-weight: 600;
          }
        }
        input[type="checkbox"] {
          width: 24px;
          height: 24px;
          margin: 0;
          @media screen and (max-width: 1180px) {
            width: 18px;
            height: 18px;
          }
        }
        label {
          position: relative;
          display: inline-block;
          vertical-align: top;
          line-height: 24px;
          margin-left: 5px;
          font-size: 20px;
          cursor: pointer;
          @media screen and (max-width: 1180px) {
            font-size: 14px;
            line-height: 18px;
          }
        }
        input[type="text"] {
          position: relative;
          display: block;
          border-radius: 10px;
          width: 100%;
          outline: none;
          background: rgba(0, 0, 0, 0.2);
          color: #fff;
          padding: 0 20px;
          box-sizing: border-box;
          border: 0;
          height: 50px;
          font-size: 18px;
          font-family: Helvetica;
          &:focus {
            background: rgba(0, 0, 0, 0.3);
          }
          &#my-debt-credit-period {
            width: calc(100% - 180px);
          }
          &#my-debt-credit-percent {
            width: calc(100% - 180px);
          }
          @media screen and (max-width: 1180px) {
            line-height: 40px;
            height: 40px;
            font-size: 16px;
            border-radius: 5px;
            padding: 0 10px;
          }
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          /* display: none; <- Crashes Chrome on hover */
          -webkit-appearance: none;
          margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
        }

        input[type="number"] {
          -moz-appearance: textfield;
          position: relative;
          display: block;
          border-radius: 10px;
          width: 100%;
          outline: none;
          background: rgba(0, 0, 0, 0.2);
          color: #fff;
          padding: 0 20px;
          box-sizing: border-box;
          border: 0;
          height: 50px;
          font-size: 18px;
          font-family: Helvetica;
          -webkit-appearance: none;
          &:focus {
            background: rgba(0, 0, 0, 0.3);
          }
          &#my-debt-credit-period {
            width: calc(100% - 180px);
          }
          &#my-debt-credit-percent {
            width: calc(100% - 180px);
          }
          @media screen and (max-width: 1180px) {
            line-height: 40px;
            height: 40px;
            font-size: 16px;
            border-radius: 5px;
            padding: 0 10px;
          }
        }
        textarea {
          position: relative;
          display: block;
          border-radius: 10px;
          width: 100%;
          outline: none;
          background: rgba(0, 0, 0, 0.2);
          color: #fff;
          padding: 10px 20px;
          box-sizing: border-box;
          border: 0;
          height: 140px;
          line-height: 24px;
          font-size: 18px;
          font-family: Helvetica;
          resize: none;
          &:focus {
            background: rgba(0, 0, 0, 0.3);
          }
          @media screen and (max-width: 1180px) {
            line-height: 20px;
            height: 100px;
            font-size: 16px;
            border-radius: 5px;
            padding: 10px 10px;
          }
        }
      }
      .pro-input {
        width: 100%;
        .modal-input {
          width: 100%;
        }
      }
      .hidden-block {
        position: relative;
        display: none;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        &.active {
          display: flex;
        }
        .select-type {
          position: absolute;
          display: block;
          border-radius: 10px;
          right: 0;
          bottom: 0;
          width: 160px;
          height: 50px;
          font-size: 18px;
          font-family: Helvetica;
          background: rgba(255, 255, 255, 0.1);
          cursor: pointer;
          .select-item-title {
            position: relative;
            display: block;
            width: 100%;
            height: 50px;
            line-height: 50px;
            padding: 0 20px;
            background: url(../img/drop_down_big.svg) no-repeat 130px 50%;
            background-size: 14px 14px;
          }
          .select-type-list {
            position: absolute;
            display: none;
            top: 52px;
            z-index: 1;
            width: 100%;
            height: auto;
            border-radius: 10px;
            background: #022036;
            overflow: hidden;
            .select-type-item {
              line-height: 50px;
              padding: 0 20px;
              &:hover {
                background: rgba(255, 255, 255, 0.1);
              }
            }
          }
          &.active {
            .select-type-list {
              display: block;
            }
          }
        }
      }

      #credit-list-items {
        width: 100%;
        margin: 0 -10px;
        margin-bottom: 30px;
        .credit-list-item {
          position: relative;
          display: flex;
          justify-content: space-between;
          border-bottom: rgba(255, 255, 255, 0.1) solid 1px;
          font-size: 14px;
          line-height: 20px;
          padding: 10px 10px;
          &:hover {
            background: rgba(0, 0, 0, 0.2);
          }
          .credit-list-item-left {
            width: calc(100% - 300px);
            span {
              position: relative;
              display: inline-block;
              margin-right: 0px;
              &:first-child {
                position: relative;
                display: inline-block;
                font-weight: bold;
                opacity: 0.5;
                width: 40px;
              }
            }
          }
          .credit-list-item-sum {
            color: rgba(255, 255, 255, 0.5);
            b {
              position: relative;
              display: block;
              color: #fff;
            }
          }
          &.old-date {
            opacity: 0.2;
          }
        }
      }

      .modal-buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: 10px;
        .add-button {
          position: relative;
          display: block;
          width: 220px;
          font-size: 20px;
          border-radius: 10px;
          height: 70px;
          line-height: 70px;
          text-align: center;
          background: #d03838;
          cursor: pointer;
          @media screen and (max-width: 1180px) {
            font-size: 16px;
            line-height: 50px;
            border-radius: 5px;
            height: 50px;
            width: 180px;
          }
          &.green-btn {
            background: #069f07;
          }
          &:hover {
            background: #ef5252;
            &.green-btn {
              background: #09b40a;
            }
          }
          &.disabled {
            opacity: 0.5;
            cursor: default;
            &:hover {
              background: #d03838;
              &.green-btn {
                background: #069f07;
              }
            }
          }
        }
        .cancel-button {
          font-size: 20px;
          line-height: 70px;
          cursor: pointer;
          @media screen and (max-width: 1180px) {
            font-size: 16px;
            line-height: 50px;
            border-radius: 5px;
            height: 50px;
            padding-right: 20px;
          }
        }
      }
    }
  }
}

.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker {
  span {
    margin-bottom: 0 !important;
  }
}
